export const PLUS_FAQ_QUESTIONS = [
  {
    sectionTitle: 'Skylight Plus FAQs',
    content: [
      {
        q: 'What is Skylight Plus?',
        a: 'Skylight Plus is a subscription service that gives you access to additional features for your Skylight for just $39/year. With Plus, you can control your frame remotely, curate photo albums, draw animated doodles, and more.',
      },
      {
        q: 'Do I need one subscription per frame?',
        a: 'No, one subscription will cover ALL your frames!',
      },
      {
        q: 'Can others in the family access these features too?',
        a: 'Yes! The Skylight Plus subscription can be used by everyone in the family who sends to your Skylight. They will simply create an account through our mobile app or online at app.ourskylight.com and link to your Skylight.',
      },
      { q: 'How long can the videos be?', a: 'Skylight supports videos up to 1 minute long.' },
      {
        q: 'Videos take up a lot of space - will my Skylight run out of space?',
        a: 'We compress your videos so that you can send hundreds of them without running out of space. And if space does get low, you can easily delete some videos from our web or mobile app.',
      },
      {
        q: 'How long can the text captions be?',
        a: "Say it short and sweet! Skylight supports captions up to 300 characters. That's enough to wish mom a happy birthday and tell her how much you love her :)",
      },
      {
        q: 'How do the text captions appear on Skylight?',
        a: "They appear at the bottom of a photo -- we format them so they're big enough to read, but don't cover up the photo.",
      },
      {
        q: 'On what devices can I use the app?',
        a: 'The Skylight app is available for desktop, tablets, iPhone, and Android.',
      },
      {
        q: 'Can I cancel Skylight Plus?',
        a: 'Absolutely. You can cancel anytime right from our website or by reaching out to our customer service team.',
      },
    ],
  },
]
